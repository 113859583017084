<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="'Edit Vesting Schedule'"
      /> -->
      <v-card-title class="mt-3 pt-6 pl-8">
        <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Edit Vesting Schedule</span>
      </v-card-title>
      <common-trs-card class="mx-6 pb-0 mb-2">
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
          class="ml-auto"
        >
          <v-card-text class="pa-0">
            <form>
              <vesting-schedule-form
                v-model="vestingScheduleData"
                :financing-rounds="financingRoundList"
                :share-terms="shareTermList"
                @update="update"
              />
            </form>
          </v-card-text>
          <v-row class="mt-5 mx-2">
            <v-col>
              <v-divider />
              <div class="d-flex justify-space-between mt-3">
                <common-trs-btn
                  type="secondary"
                  medium
                  class="pull-right mr-2"
                  text
                  :to="{name: 'ManageSecurities'}"
                >
                  Cancel
                </common-trs-btn>
                <common-trs-btn
                  type="primary"
                  medium
                  class="white--text pull-right"
                  text
                  :disabled="!valid"
                  :loading="loading"
                  @click="save"
                >
                  Save
                </common-trs-btn>
              </div>
            </v-col>
          </v-row>
        </validation-observer>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import VestingScheduleForm from '@/components/common/captable/components/forms/VestingScheduleForm'

  export default {
    name: 'CreateConvertibleTerm',

    components: {
      VestingScheduleForm,
    },

    data: () => ({
      loading: false,
      financingRoundList: [],
      shareTermList: [],
      vestingScheduleData: {},
      apiData: '',
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      termId () {
        return this.$route.query.id
      },
    },

    created () {
      this.loadVestingScheduleData()
    },

    methods: {
      async loadVestingScheduleData () {
        this.loading = true
        try {
          const resp = await captableService.getVestingScheduleById(this.captableId, this.termId)
          if (resp && resp.data) {
            this.vestingScheduleData = resp.data.vesting_schedule
          }
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },

      makeApiData () {
        if (this.vestingScheduleData.custom && this.vestingScheduleData.custom.length > 0) {
          delete this.vestingScheduleData.time_based
          const result = this.vestingScheduleData.custom.map(item => {
            return { ...item, percent: item.percent / 100 }
          })
          this.vestingScheduleData.custom = result
          this.apiData = {
            vesting_schedule: this.vestingScheduleData,
          }
        } else {
          // Time based
          this.apiData = this.makeApiDataForCliff()
        }
        return this.apiData
      },

      makeApiDataForCliff () {
        delete this.vestingScheduleData.custom
        if (this.vestingScheduleData.time_based && this.vestingScheduleData.time_based.cliff) {
          // Has cliff
          let hasCliffApiData = {}
          hasCliffApiData = {
            vesting_schedule: {
              ...this.vestingScheduleData,
              immediate_vest_percent: this.vestingScheduleData.immediate_vest_percent / 100,
              time_based: {
                ...this.vestingScheduleData.time_based,
                cliff: {
                  ...this.vestingScheduleData.time_based.cliff,
                  percent: this.vestingScheduleData.time_based.cliff.percent / 100,
                },
              },
            },
          }

          return hasCliffApiData
        } else {
          // No cliff
          let hasNoCliffAPiData = {}
          hasNoCliffAPiData = {
            vesting_schedule: {
              ...this.vestingScheduleData,
              immediate_vest_percent: this.vestingScheduleData.immediate_vest_percent / 100,
            },
          }

          return hasNoCliffAPiData
        }
      },

      async save () {
        this.loading = true
        try {
          this.clearNullValues()
          const apiData = this.makeApiData()
          await captableService.updateVestingScheduleById(this.captableId, this.termId, apiData)
          this.$store.dispatch('app/message', { text: 'Vesting schedule saved successfully.' })
          this.loading = false
          this.$router.push({ name: 'ManageSecurities' })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to update Vesting schedule. Please try again later.',
          })
        }
      },
      update (val) {
        this.vestingScheduleData = val
      },
      clearNullValues () {
        if (this.vestingScheduleData.convertible_type === this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT) {
          this.vestingScheduleData.convertible_debt_terms = {}
        } else if (!this.vestingScheduleData.convertible_debt_terms) {
          delete this.vestingScheduleData.convertible_debt_terms
        }
        if (!this.vestingScheduleData.interest_terms) {
          delete this.vestingScheduleData.interest_terms
        }
      },
      close () {
        this.$router.push({ name: 'ManageSecurities' })
      },
    },
  }
</script>
